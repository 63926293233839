import { FormArray, FormGroup } from '@angular/forms';

export class ErrorSet {
  /**
   *
   * Mapping form error
   * @returns string
   */
  static setError(form: FormGroup) {
    const error: any = {};
    const errorArray: any = {};

    Object.keys(form.value).forEach((k) => {
      const object = form.controls[k];
      if (object instanceof FormArray) {
        error[k] = this.setErrorArray(object);
        errorArray[k] = this.queryErrorArray(object);
      } else {
        if (object.errors) {
          error[k] = object.errors;
        }
      }
    });
    // Scroll to error element
    this.scrollToError(error, errorArray);

    return error;
  }

  private static scrollToError(error: any, errorArray: any) {
    let el!: Element | null;
    Object.keys(errorArray).forEach((k) => {
      if (errorArray[k].length === 0) {
        delete errorArray[k];
      }
    });

    const key = Object.keys(error)[0];
    if (!Array.isArray(error[key])) {
      el = document.querySelector('[name=' + Object.keys(error)[0] + ']');
    } else {
      if (Object.keys(errorArray).length !== 0) {
        const k = Object.keys(errorArray)[0];
        for (let i = 0; i <= error[k].length; i++) {
          if (error[key][i]) {
            if (Object.keys(error[key][i]).length > 0) {
              el = document.querySelector('[name=' + k + '_' + Object.keys(error[k])[i] + '_' + Object.keys(error[k][i])[0] + ']');
              // if (!el) {
              //   el = document.querySelector('#' + k + '_' + Object.keys(error[k])[i] + '_' + Object.keys(error[k][i])[0]);
              // }
              break;
            }
          }
        }
      }
    }

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private static setErrorArray(form: FormArray) {
    const errors: any[] = [];

    form.controls.forEach((control: any) => {
      const error: any = {};
      Object.keys(control.value).forEach((key) => {
        if (control.controls[key].errors) {
          error[key] = control.controls[key].errors;
        }
      });
      errors.push(error);
    });

    return errors;
  }

  private static queryErrorArray(form: FormArray) {
    const errors: any[] = [];

    form.controls.forEach((control: any) => {
      const error: any = {};
      Object.keys(control.value).forEach((key, index) => {
        if (control.controls[key].errors) {
          error[key] = control.controls[key].errors;
          errors.push(error);
        }
      });
    });

    return errors;
  }
}
