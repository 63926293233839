import { FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import { ErrorMessage } from './error-message';
import { ErrorSet } from './error-set';

export class ErrorHepler {
  /**
   *
   * Parsing error message
   * @returns string
   */
  static errorMessage(error: ValidationErrors | any) {
    return ErrorMessage.getErrorMessage(error);
  }

  /**
   *
   * Mapping form error
   * @returns string
   */
  static setError(form: FormGroup) {
    return ErrorSet.setError(form);
  }
}
