import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Datatable, DatatableColumn, DatatableParamsChange } from './datatable.model';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss',
})
export class DatatableComponent {
  @Input()
  set source(value: Datatable<any>) {
    if (!value) {
      return;
    }

    value.data.forEach((r, i) => {
      r.order = value.limit * (value.page - 1) + i + 1;
    });
    this.dataSource = value;
    this.params.limit = value.limit;
    // setTimeout(() => {
    // this.doRecalculateTable();
    // }, 100);

    this.initPagination();
  }

  @Input()
  set columns(value: DatatableColumn[]) {
    const columns = [...value];

    if (!this.disableOrder) {
      columns.unshift({
        prop: 'order',
        name: '#',
        sortable: false,
        rowSpan: true,
        width: '65px',
        cellClass: 'text-center px-25',
        headerClass: 'd-flex justify-content-center',
      });
    }

    this.dataColumns = columns;
  }

  dataSource!: Datatable<any>;
  dataColumns: DatatableColumn[] = [];

  @Input() disableOrder: boolean = false;
  @Input() disableFooter: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() classes!: string;
  @Input() theadClass?: string;
  @Input() tBodyClass?: string;

  @Output() datatableChange = new EventEmitter();
  @Output() rowClicked = new EventEmitter();

  pageSizeList = [
    { id: 10, name: 10 },
    { id: 20, name: 20 },
    { id: 50, name: 50 },
    { id: 100, name: 100 },
  ];

  pageList: number[] = [];
  formPageSize = new FormControl();

  private params: Partial<DatatableParamsChange> = {};
  page!: number;
  pageTotal!: number;
  total!: number;
  limit!: number;

  ngOnInit(): void {}

  doRecalculateTable() {
    // force to redraw table for calculate new width
    setTimeout(() => {
      this.dataSource.data = this.dataSource.data ? [...this.dataSource.data] : [];
    }, 600);
  }

  columnSort(column: DatatableColumn) {
    this.dataColumns.forEach((col) => {
      col.sorts = col.prop === column.prop ? (col.sorts === 'asc' ? 'desc' : 'asc') : undefined;
    });

    const order = `"${column.sortkey || column.prop}" ${column.sorts}`;
    this.params = { ...this.params, order };
    this.datatableChange.emit(this.params);
  }

  paginationChange() {
    if (this.params.page !== this.page || this.params.limit !== this.limit) {
      this.params = { ...this.params, page: this.page, limit: this.limit };
      this.datatableChange.emit(this.params);
    }
  }

  onRowClick(row: any) {
    this.rowClicked.emit(row);
  }

  //////////////////////////////////////////////////////
  /// Pagination
  //////////////////////////////////////////////////////

  initPagination() {
    if (this.disableFooter) return;

    this.page = this.dataSource.page;
    this.total = this.dataSource.total;
    this.pageTotal = this.dataSource.total_page;
    this.limit = this.dataSource.limit;

    this.formPageSize.setValue(this.limit, { emitEvent: false, onlySelf: true });

    this.generatePagination();
  }

  onPageSizeChange() {
    this.limit = Number(this.formPageSize.value);
    this.paginationChange();
  }

  generatePagination() {
    const limit = 5;
    const pageTotal = this.pageTotal;
    const pageList: number[] = [];
    const currentPage = this.page;

    let start = Math.max(1, currentPage - 2);
    let end = Math.min(pageTotal, currentPage + 2);

    if (end - start + 1 < limit) {
      if (start === 1) {
        end = Math.min(pageTotal, start + limit - 1);
      } else if (end === pageTotal) {
        start = Math.max(1, end - limit + 1);
      }
    }

    for (let i = start; i <= end; i++) {
      pageList.push(i);
    }

    this.pageList = pageList;
  }

  onPageSelected(value: number) {
    this.page = value;
    this.generatePagination();
    this.paginationChange();
  }

  pagePrevious() {
    this.page--;
    this.generatePagination();
    this.paginationChange();
  }

  pageNext() {
    this.page++;
    this.generatePagination();
    this.paginationChange();
  }

  pagePreviousToFirst() {
    this.page = 1;
    this.generatePagination();
    this.paginationChange();
  }

  pageNextToTotal() {
    this.page = this.pageTotal;
    this.generatePagination();
    this.paginationChange();
  }
}
