<div class="">
  <label [for]="id" class="block mb-2 text-sm font-medium text-[#797979] dark:text-white">
    {{ label }}
    <span *ngIf="isRequired" class="text-red-600">*</span>
  </label>
  <div class="relative" [id]="id">
    <button
      type="button"
      (click)="toggleOption()"
      class="relative py-3 ps-2 pe-9 flex gap-x-2 text-nowrap w-full focus:ring-1 cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-neutral-600"
      [ngClass]="isError ? 'form-error' : 'form-info'"
    >
      @if (display) {
      <span class="truncate">{{ display }}</span>

      }@else {

      <span class="truncate text-gray-400">{{ placeholder }}</span>
      }
    </button>

    <div class="absolute top-1/2 end-2.5 -translate-y-1/2">
      <div class="flex items-center">
        @if (display && clearable) {
        <div class="mr-2 cursor-pointer" (click)="clearSelected()">
          <svg
            class="w-4 h-4 text-gray-800 hover:text-red-600 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6" />
          </svg>
        </div>
        }
        <svg
          class="shrink-0 size-4 text-gray-500 dark:text-neutral-500"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="m7 15 5 5 5-5"></path>
          <path d="m7 9 5-5 5 5"></path>
        </svg>
      </div>
    </div>

    <div
      [id]="dsId"
      [hidden]="isHideOption"
      class="absolute mt-2 z-50 w-full max-h-72 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-neutral-900 dark:border-neutral-700 top-full opened p-1"
      role="listbox"
      aria-orientation="vertical"
      style="margin-top: 10px"
    >
      <div class="bg-white p-2 sticky top-0">
        @if (searchable) {
        <input
          type="text"
          placeholder="Search..."
          class="block w-full text-sm border-gray-200 rounded-lg focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 py-2 px-3"
          [formControl]="search"
          autocomplete="off"
        />
        }
      </div>
      @for (option of optionItems; track $index) {
      <div
        (click)="selectedOption(option)"
        class="cursor-pointer py-2 px-4 w-full text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:text-neutral-200 dark:focus:bg-neutral-800"
      >
        <div class="flex justify-between items-center w-full">
          <span>{{ option[bindLabel] }}</span>
        </div>
      </div>
      }@empty {
      <div class="px-4 py-2 text-sm text-gray-800 dark:text-neutral-200">No Items found</div>
      }
    </div>
  </div>

  <p class="mt-1 text-sm text-red-600 dark:text-red-500">{{ errorMessage }}</p>
</div>
