import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ErrorHepler } from '../../utils/error-helper/error-helper';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true }],
})
export class SelectComponent {
  @Input() name!: string;
  @Input() id!: string;
  @Input() isRequired : boolean = false;
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input()
  set error(value: any) {
    this.errorMessage = '';
    this.isError = false;

    if (value) {
      this.isError = true;
      this.errorMessage = ErrorHepler.errorMessage(value);
    }
  }

  @Input() options: any[] = [];
  @Input() bindValue: string = 'id';
  @Input() bindLabel: string = 'name';

  @Output() valueChange = new EventEmitter();

  form = new FormControl();

  onChange = (value: string) => {};
  onTouched = (value: string) => {};

  subscription: Subscription = new Subscription();

  errorMessage: string = '';
  isError = false;

  ngOnInit(): void {
    const sb = this.form.valueChanges.pipe().subscribe((val) => {
      this.onChange(this.form.value);
    });

    this.subscription.add(sb);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    this.form.setValue(value);
  }

  setDisabledState?(isDisabled: boolean): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelectChange() {
    this.valueChange.emit(this.form.value);
  }
}
